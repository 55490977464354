<template>
  <div>
    <el-dialog
      title="更多筛选条件"
      :visible.sync="filterModal"
      :close-on-click-modal="false"
      :show-close="false"
      center
      width="640px"
    >
      <el-form class="demo-form-inline" size="medium" label-width="0">
        <el-row :gutter="20">
          <el-col
            :span="item.attrs.includes('span') ? item.span : 8"
            v-show="item.attrs.includes('isHide') ? item.isHide : true"
            v-for="item in hideList"
            :key="item.key"
          >
            <el-form-item
              :label="item.attrs.includes('label') ? item.label : ''"
            >
              <component
                size="large"
                :is="item.copName"
                v-model="item[item.key]"
                :prefix-icon="item.copName === 'el-input' ? 'fa fa-search' : ''"
                :cop-options="
                  item.attrs.includes('options') ? item.options : []
                "
                :placeholder="
                  item.attrs.includes('placeholder') ? item.placeholder : ''
                "
                show-word-limit
                @input="filterInput(item, item.key, item[item.key])"
                @change="filterChange(item, item.key, item[item.key])"
                :type="item.attrs.includes('copType') ? item.copType : ''"
                :value-format="
                  item.attrs.includes('valueFormat') ? item.valueFormat : ''
                "
                :start-placeholder="
                  item.attrs.includes('startPlaceholder')
                    ? item.startPlaceholder
                    : ''
                "
                :end-placeholder="
                  item.attrs.includes('endPlaceholder')
                    ? item.endPlaceholder
                    : ''
                "
                clearable
              >
                <el-option
                  v-for="(o, i) in item.options"
                  :key="i"
                  :label="o.label"
                  :value="o.value"
                ></el-option>
              </component>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="filterModal = false">取 消</el-button>
        <el-button type="primary" @click="filterModal = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-form
      class="demo-form-inline"
      inline
      size="medium"
      label-width="0"
      ref="tilterFormRef"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <!-- :label="item.attrs.includes('label') ? item.label + ':' : ''" -->
          <el-form-item
            v-show="item.attrs.includes('isHide') ? item.isHide : true"
            v-for="item in showList"
            :key="item.key"
            :label="
              item.attrs.includes('placeholder')
                ? $t(item.placeholder) + ':'
                : ''
            "
            :label-width="
              item.attrs.includes('labelWidth') ? item.labelWidth : '100'
            "
          >
            <component
              size="small"
              :style="{ width: item.width ? item.width + 'px' : '256px' }"
              :is="item.copName"
              v-model.trim="item[item.key]"
              :prefix-icon="item.copName === 'el-input' ? 'fa fa-search' : ''"
              :cop-options="item.attrs.includes('options') ? item.options : []"
              :placeholder="
                item.attrs.includes('placeholder') ? $t(item.placeholder) : ''
              "
              show-word-limit
              @input="filterInput(item, item.key, item[item.key])"
              @change="filterChange(item, item.key, item[item.key])"
              :type="item.attrs.includes('copType') ? item.copType : ''"
              :options="
                item.attrs.includes('cascader') ? item.cascaderOptions : []
              "
              :show-all-levels="
                item.attrs.includes('showAllLevels') ? item.showAllLevels : true
              "
              :value-format="
                item.attrs.includes('valueFormat') ? item.valueFormat : ''
              "
              :start-placeholder="
                item.attrs.includes('startPlaceholder')
                  ? item.startPlaceholder
                  : ''
              "
              :end-placeholder="
                item.attrs.includes('endPlaceholder') ? item.endPlaceholder : ''
              "
              :props="{
                ...item.props,
              }"
              clearable
              filterable
            >
              <el-option
                v-for="(o, i) in item.options"
                :key="i"
                :label="o.label"
                :value="o.value"
              ></el-option>
            </component>
          </el-form-item>
          <el-form-item label="" v-if="hideList.length">
            <el-button
              icon="el-icon-more"
              title="更多筛选"
              @click="filterModal = true"
            ></el-button>
          </el-form-item>
          <el-form-item label="" v-if="showList.length">
            <el-button style="width: 80px" size="small" @click="handleReset">
              {{ $t("global.reset") }}
            </el-button>
            <el-button
              style="width: 80px"
              type="primary"
              size="small"
              @click="handleQuery"
            >
              {{ $t("global.query") }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'table-filter',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      default: () => { }
    },
    showList: {
      type: Array,
      default: () => [] // key copName必选
    },
    hideList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filterModal: false
    }
  },
  methods: {
    handleReset () {
      var tempPage = { ...this.$parent.formInline.page }
      // this.$parent.formInline = {
      //   name: '',
      //   query: '',
      //   page: { ...tempPage }
      // }
      var showList_back = []

      this.showList.forEach(ele => {
        showList_back.push(ele)
      })
      for (var i in this.$parent.formInline) {
        if (i != 'page') {
          this.$set(this.$parent.formInline, i, '')
        } else {
          this.$set(this.$parent.formInline, i, { ...tempPage })
        }
      }

      showList_back.forEach(item => {
        item[item.key] = ''
      })
      this.$set(this.$parent, 'showList', [...showList_back])
      this.handleQuery()
    },
    handleQuery () {
      this.$emit('filterChange', {})
    },
    filterInput (item, key, value) {
      if (item.copName !== 'el-input') return false
      const obj = this.value
      this.showList.forEach(o => {
        obj[o.key] = o[o.key]
      })
      this.hideList.forEach(o => {
        obj[o.key] = o[o.key]
      })
      this.$emit('change', obj)
      // this.$emit('filterChange', { item, key, value })
    },
    filterChange (item, key, value) {
      if (item.copName === 'el-input') return false
      const obj = this.value
      this.showList.forEach(o => {
        obj[o.key] = o[o.key]
      })
      this.hideList.forEach(o => {
        obj[o.key] = o[o.key]
      })
      this.$emit('change', obj)
      // console.log()
      this.$parent.showList.splice(0, 1, this.$parent.showList[0])
      // this.$emit('filterChange', { item, key, value })
    }
  }
}
</script>
